export enum Minigame {
  TRIPOD = "tripod",
  HUNGER_GAME = "hunger-game",
}

export enum Menu {
  MAIN = "main",
  MINIGAME = "minigame",
  LEADERBOARD = "leaderboard",
}
